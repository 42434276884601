import ROUTES from "../../routes.js"

const {
  SALE_VIEW_DATA: SALE,
  RENT_VIEW_DATA: RENT,
  TEMPORARY_VIEW_DATA: TEMPORARY,
} = JSON.parse(process.env.extra_data || ROUTES)

export const getArrayOperationById = (_operation = [1, 2, 3]) => {
  if (_operation === "") {
    return [1, 2, 3]
  }
  // if (_operation === [1, 2, 3]) {
  //   return _operation
  // }
  const operation_id = [
    { name: SALE.route.toLowerCase(), id: 1 },
    { name: RENT.route.toLowerCase(), id: 2 },
    { name: TEMPORARY.route.toLowerCase(), id: 3 },
  ]

  const operation = _operation
    ? [
        operation_id.find(
          element => element.name.toLowerCase() === _operation.toLowerCase()
        ).id,
      ]
    : []
  return operation === "" ? [1, 2, 3] : operation
}

export const getArrayTypesById = (custom_types, total_filters) => {
  let id_type
  if (total_filters) {
    if (Array.isArray(custom_types)) {
      id_type = custom_types.map(element => element.id)
    } else {
      id_type =
        custom_types !== ""
          ? [
              total_filters.objects.property_types.find(
                element =>
                  element?.type?.toLowerCase() === custom_types?.toLowerCase()
              )?.id,
            ]
          : []
    }
    const types = custom_types ? id_type : []
    console.log(types)
    return types
  }
  return [7]
}

export const getSumaryFilterDefault = ({
  operation = [1, 2, 3],
  property_types = [],
}) => {
  const summary_filters = JSON.stringify({
    current_localization_id: [],
    current_localization_type: "",
    price_from: 0,
    price_to: 9999999999999,
    operation_types: operation,
    property_types: property_types,
  })
  return summary_filters
}

export const makeParamsGetByFilters = (
  orders = "",
  offset = 0,
  polygon = []
) => {
  var params_get = ""
  params_get =
    "cache=1&save=" +
    (polygon.length > 0 ? "1" : "0") +
    "&limit=" +
    (polygon.length > 0 ? "2000&chunk=1" : "50") +
    "&offset=" +
    offset +
    "&order-by=" +
    orders.order_by +
    "&order=" +
    orders.order
  return params_get
}

export const makeFormDataByFilters = (
  filters,
  coordinates = [],
  polygon = []
) => {
  var formdata = ""
  const c_formData = new FormData()
  var tags_both = []
  var specials = []
  if (filters?.operation === undefined) {
    return null
  }
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "temporario", id: 3 },
  ]
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
    { name: "7 ambientes", id: 7 },
    { name: "8 ambientes", id: 8 },
    { name: "mas de 9 ambientes", id: 9 },
  ]
  if (filters.operation) {
    formdata +=
      "&operation=" +
      operation_id.find(
        element => element.name === filters.operation.toLowerCase()
      )?.id
  }
  if (filters?.type?.length > 0) {
    formdata += "&tipo=" + filters.type.map(element => element.id)
  }
  if (filters?.location?.length > 0) {
    formdata +=
      "&localization-divisions=" +
      filters.location.map(element => element.id) +
      "&localization-type=division"
  }
  if (filters?.services?.length > 0) {
    tags_both.push(filters.services.map(element => element.id))
  }
  if (filters?.specials?.length > 0) {
    specials.push(filters.specials.map(element => element.id))
    formdata += "&custom_tags=" + specials.map(element => element)
  }
  if (filters?.generals?.length > 0) {
    tags_both.push(filters.generals.map(element => element.id))
  }
  if (filters?.environments_types?.length > 0) {
    tags_both.push(filters.environments_types.map(element => element.id))
  }
  if (
    filters?.environments_types?.length > 0 ||
    filters?.generals?.length ||
    filters?.services?.length > 0
  ) {
    formdata += "&tags=" + tags_both
  }
  if (filters?.surface?.type) {
    var type_surface = ""
    const surfaceMin = filters.surface.surfaceMin
      ? filters.surface.surfaceMin
      : 0
    const surfaceMax = filters.surface.surfaceMax
      ? filters.surface.surfaceMax
      : 999999999
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed"
    } else {
      type_surface = filters.surface.type
    }
    formdata +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin +
      "-" +
      surfaceMax
  }
  if (filters?.price?.type) {
    var price_type = ""
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars"
        break
      }
      default:
        price_type = "usd"
    }

    const priceMin = filters?.price?.priceMin ? filters?.price?.priceMin : 0
    const priceMax = filters?.price?.priceMax
      ? filters?.price?.priceMax
      : 10000000

    formdata +=
      "&currency=" + price_type + "&price=" + priceMin + "-" + priceMax
  }
  if (filters?.environments !== "" && filters?.environments !== undefined) {
    const rooms_string = "&rooms=" + filters.environments
    formdata += rooms_string
  }
  if (filters?.bedrooms !== "" && filters?.bedrooms !== undefined) {
    const suites_string = "&suites=" + filters.bedrooms
    formdata += suites_string
  }
  if (filters?.age) {
    switch (filters.age.toLowerCase()) {
      case "en construccion": {
        formdata += "&age=-1"
        break
      }
      case "a estrenar": {
        formdata += "&age=0"
        break
      }
      case "1 a 5 años": {
        formdata += "&age=1-5"
        break
      }
      case "6 a 10 años": {
        formdata += "&age=6-10"
        break
      }
      case "11 a 20 años": {
        formdata += "&age=11-20"
        break
      }
      case "21 a 50 años": {
        formdata += "&age=21-50"
        break
      }
      case "+ 50 años": {
        formdata += "&age=50-99"
        break
      }
    }
  }
  if (filters?.price?.is_off) {
    formdata += "&appraisal=decrease"
  }
  if (filters?.address) {
    formdata += "&fake_address=" + filters.address
  }
  if (coordinates?.length > 0) {
    formdata += "&longitude=" + coordinates[0] + "," + coordinates[1]
  }
  if (polygon?.length > 0) {
    formdata += "&force=" + polygon[0].lng
  }
  return formdata
}

export const makeFormDataByFiltersMediacore = (
  filters,
  coordinates = [],
  polygon = []
) => {
  var params = ""
  const c_formData = new FormData()
  if (filters.operation) {
    let operation = filters.operation.includes("temp")
      ? "alquiler-temporario"
      : filters.operation.toLowerCase().replaceAll(" ", "-")
    params += `operation_type=${operation}`
    c_formData.append("operation_type", operation)
  }
  if (filters.address) {
    params += `&address=${filters.address}`
    c_formData.append("address", filters.address)
  }
  if (filters?.price?.is_off) {
    c_formData.append("is_less_than_previous_price", true)
  }
  if (filters?.type?.length > 0) {
    params += `&property_type=${filters.type.map(element => element.name)}`
    c_formData.append(
      "property_type",
      filters.type.map(element => element.name)
    )
  }
  if (filters?.environments !== "" && filters?.environments !== undefined) {
    const rooms_string = "&room_amount=" + filters.environments
    params += rooms_string
    c_formData.append("room_amount", filters.environments)
  }
  if (filters?.bedrooms !== "" && filters?.bedrooms !== undefined) {
    const suites_string = "&suite_amount=" + filters.bedrooms
    params += suites_string
    c_formData.append("suite_amount", filters.bedrooms)
  }
  if (filters?.location?.length > 0) {
    params +=
      filters.location.filter(loc => loc.type === "neighborhood").length > 0
        ? `&neighborhood=${filters.location
            .filter(loc => loc.type === "neighborhood")
            .map(element => element.name)}`
        : ""
    params +=
      filters.location.filter(loc => loc.type === "state").length > 0
        ? `&state=${filters.location
            .filter(loc => loc.type === "state")
            .map(element => element.name)}`
        : ""
    params +=
      filters.location.filter(loc => loc.type === "city").length > 0
        ? `&city=${filters.location
            .filter(loc => loc.type === "city")
            .map(element => element.name)}`
        : ""

    c_formData.append(
      "neighborhood",
      filters.location
        .filter(loc => loc.type === "neighborhood")
        .map(element => element.name)
    )
    c_formData.append(
      "state",
      filters.location
        .filter(loc => loc.type === "state")
        .map(element => element.name)
    )
    c_formData.append(
      "city",
      filters.location
        .filter(loc => loc.type === "city")
        .map(element => element.name)
    )
  }
  if (filters?.generals?.length > 0) {
    params += `&extra_general=${filters.generals.map(element => element.name)}`
    c_formData.append(
      "extra_general",
      filters.generals.map(element => element.name)
    )
  }
  if (filters?.services?.length > 0) {
    params += `&extra_services=${filters.services.map(element => element.name)}`
    c_formData.append(
      "extra_services",
      filters.services.map(element => element.name)
    )
  }
  if (filters?.environments_types?.length > 0) {
    params += `&extra_amenities=${filters.environments_types.map(
      element => element.name
    )}`
    c_formData.append(
      "extra_amenities",
      filters.environments_types.map(element => element.name)
    )
  }
  if (filters?.age) {
    params += `&age=${filters.age}`
    c_formData.append("age", filters.age)
  }
  if (filters?.price?.type) {
    var price_type = ""
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars"
        break
      }
      default:
        price_type = "usd"
    }

    const priceMin = filters?.price?.priceMin ? filters?.price?.priceMin : 0
    const priceMax = filters?.price?.priceMax
      ? filters?.price?.priceMax
      : 10000000

    params += "&currency=" + price_type + "&price=" + priceMin + "-" + priceMax
    c_formData.append("currency", price_type)
    c_formData.append("price", priceMin + "-" + priceMax)
  }
  if (filters?.surface?.type) {
    var type_surface = ""
    const surfaceMin = filters.surface.surfaceMin
      ? filters.surface.surfaceMin
      : 0
    const surfaceMax = filters.surface.surfaceMax
      ? filters.surface.surfaceMax
      : 999999999
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "cubierta"
    } else {
      type_surface = "total"
    }
    params +=
      "&surface_type=" +
      type_surface +
      "&surface=" +
      surfaceMin +
      "-" +
      surfaceMax
    c_formData.append("surface_type", type_surface)
    c_formData.append("surface", surfaceMin + "-" + surfaceMax)
  }
  console.log(c_formData)
  return c_formData
}

export const makeCoordinatesMediacore = (coordinates = [], polygon = []) => {
  let params = { coordinates: [] }
  if (polygon) {
    params.coordinates = polygon
  }
  if (coordinates) {
    params.coordinates = coordinates
  }
  return params.coordinates
}

export const makeParamsByFiltersMediacore = (filters, polygon, coordinates) => {
  let params = {}
  if (filters.operation) {
    let operation = filters.operation.includes("temp")
      ? "alquiler-temporario"
      : filters.operation.toLowerCase().replaceAll(" ", "-")
    params.operation_type = `${operation}`
  }
  if (polygon.length > 0) {
    params.coordinates = polygon
  }
  if (coordinates.length > 0) {
    console.log(coordinates)
    // Definir las coordenadas de los bordes del mapa
    var latitudNorte = coordinates[3] //-34.10877770103206; // Latitud del borde norte
    var latitudSur = coordinates[2] //-35.00675812035054;  // Latitud del borde sur
    var longitudOeste = coordinates[1] //-58.234252628261004; // Longitud del borde oeste
    var longitudEste = coordinates[0] //-59.088439639979754;  // Longitud del borde este

    // var latitudNorte = 40.7128; // Latitud del borde norte
    // var latitudSur = 34.0522;  // Latitud del borde sur
    // var longitudOeste = -74.0060; // Longitud del borde oeste
    // var longitudEste = -118.2437;  // Longitud del borde este

    // Calcular las coordenadas de las esquinas del mapa
    var esquinaSuperiorIzquierda = { lat: latitudNorte, lng: longitudOeste }
    var esquinaSuperiorDerecha = { lat: latitudNorte, lng: longitudEste }
    var esquinaInferiorIzquierda = { lat: latitudSur, lng: longitudOeste }
    var esquinaInferiorDerecha = { lat: latitudSur, lng: longitudEste }

    params.coordinates = [
      esquinaSuperiorIzquierda,
      esquinaSuperiorDerecha,
      esquinaInferiorDerecha,
      esquinaInferiorIzquierda,
    ]
  }
  if (filters.address) {
    params.address = filters.address
  }
  if (filters?.price?.is_off) {
    params.is_less_than_previous_price = true
  }
  if (filters?.type?.length > 0) {
    params.property_type = filters.type.map(element => element.name).join()
  }
  if (filters?.environments !== "" && filters?.environments !== undefined) {
    params.room_amount = filters.environments
  }
  if (filters?.bedrooms !== "" && filters?.bedrooms !== undefined) {
    params.suite_amount = filters.bedrooms
  }
  if (filters?.location?.length > 0) {
    filters.location.filter(loc => loc.type === "neighborhood").length > 0
      ? (params.neighborhood = filters.location
          .filter(loc => loc.type === "neighborhood")
          .map(element => element.name)
          .join())
      : ""
    filters.location.filter(loc => loc.type === "state").length > 0
      ? (params.state = filters.location
          .filter(loc => loc.type === "state")
          .map(element => element.name)
          .join())
      : ""
    filters.location.filter(loc => loc.type === "city").length > 0
      ? (filtersparams.city = filters.location
          .filter(loc => loc.type === "city")
          .map(element => element.name)
          .join())
      : ""
  }
  if (filters?.generals?.length > 0) {
    params.extra_general = filters.generals.map(element => element.name).join()
  }
  if (filters?.services?.length > 0) {
    params.extra_services += filters.services
      .map(element => element.name)
      .join()
  }
  if (filters?.environments_types?.length > 0) {
    params.extra_amenities = filters.environments_types
      .map(element => element.name)
      .join()
  }
  if (filters?.age) {
    params.age = filters.age
  }
  if (filters?.price?.type) {
    var price_type = ""
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars"
        break
      }
      default:
        price_type = "usd"
    }

    const priceMin = filters?.price?.priceMin ? filters?.price?.priceMin : 0
    const priceMax = filters?.price?.priceMax
      ? filters?.price?.priceMax
      : 10000000

    params.currency = price_type
    params.price = priceMin + "-" + priceMax
  }
  if (filters?.surface?.type) {
    var type_surface = ""
    const surfaceMin = filters.surface.surfaceMin
      ? filters.surface.surfaceMin
      : 0
    const surfaceMax = filters.surface.surfaceMax
      ? filters.surface.surfaceMax
      : 999999999
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "cubierta"
    } else {
      type_surface = "total"
    }
    params.surface_type = type_surface
    params.surface = surfaceMin + "-" + surfaceMax
  }
  console.log(params)
  return params
}

export const makeParamsCoordinates = (filters, coordinates, polygon) => {
  const formData = new FormData()
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "temporario", id: 3 },
  ]
  if (filters.operation) {
    const opertaionID = operation_id.find(
      element => element?.name === filters?.operation?.toLowerCase()
    )?.id
    formData.append("operation", opertaionID)
  }
  if (coordinates?.length > 0) {
    formData.append("longitude", coordinates[0] + "," + coordinates[1])
    formData.append("latitude", coordinates[2] + "," + coordinates[3])
    // formdata += "&longitude=" +  + "&latitude=" + coordinates[2] + "," + coordinates[3];
  }
  if (polygon) {
    let polygon_coordinates = []
    for (let index = 0; index < polygon.length; index++) {
      formData.append("coordinates[" + index + "][lat]", polygon[index].lat)
      formData.append("coordinates[" + index + "][lng]", polygon[index].lng)
    }
  }
  return formData
}

export const makeCustom = custom => {
  const { name, id } = custom
  return {
    name,
    id,
  }
}

export const getServices = filters => {
  return filters.tags.filter(element => element.tag_type === 1)
}

export const getEnvironment = filters => {
  return filters.tags.filter(element => element.tag_type === 2)
}

export const getGenerals = filters => {
  return filters?.tags?.filter(element => element.tag_type === 3)
}

export const getCustoms = filters => {
  if (filters) {
    return filters?.data?.objects
  }
  return []
}

export const getView = route => {
  console.log("actualizo esto con " + route.pathname.split("/")[1])
  return route.pathname.split("/")[1]
}

export const getOperationView = filters => {
  if (filters !== undefined) {
    const {
      SALE_VIEW_DATA: SALE,
      RENT_VIEW_DATA: RENT,
      TEMPORARY_VIEW_DATA: TEMPORARY,
    } = JSON.parse(process.env.extra_data || ROUTES)
    if (filters.operation === TEMPORARY.route) {
      return TEMPORARY.name
    }
    return filters.operation
  }
}

export const checkOperation = (filters, operation) => {
  if (filters !== undefined) {
    return filters.operation_types.find(
      element => element.operation_type === operation
    )
  }
}

export const checkEnvironment = (filters, environment) => {
  if (filters !== undefined) {
    if (environment === 9) {
      return filters.room_amount.find(element => element.amount >= environment)
    }
    return filters.room_amount.find(element => element.amount === environment)
  }
}
export const checkBedroom = (filters, bedroom) => {
  if (filters !== undefined) {
    if (bedroom === 9) {
      return filters.suite_amount.find(element => element.amount >= bedroom)
    }
    return filters.suite_amount.find(element => element.amount === bedroom)
  }
}

export const getCodeOperation = operation => {
  switch (operation.toLowerCase()) {
    case "venta": {
      return 1
    }
    case "alquiler": {
      return 2
    }
    case "alquiler-temporario": {
      return 3
    }
    default: {
      return 0
    }
  }
}

export const checkView = (route, location) => {
  const path = route.pathname.split("/")
  if (
    path.find(element => element.toLowerCase() === location.toLowerCase()) ===
    undefined
  ) {
    return false
  }
  return true
}

export const getType = filters => {
  return filters.type
}

export const getTypes = totalFilters => {
  return totalFilters.property_types
}

export const getLocations = totalFilters => {
  return totalFilters.locations
}

export const textTypes = types => {
  let text = ""
  types.map((type, index) => {
    text += type.name
    if (
      type.name[type.name.length - 1] === "n" ||
      type.name[type.name.length - 1] === "l" ||
      type.name[type.name.length - 1] === "s"
    ) {
      text += "es"
    } else {
      text += "s"
    }
    if (type.name[type.name.length - 1] === "h") {
      text += "s"
    }
    if (index < types.length - 1) text += ", "
  })
  return text
}

export const getProducer = filters => {
  let text = ""
  if (filters.producer_id) {
    text += " - "
  }
  if (filters.producer_id?.split("-").length === 3) {
    text +=
      filters.producer_id.split("-")[1] +
      " " +
      filters.producer_id.split("-")[2]
  }
  if (filters.producer_id?.split("-").length === 4) {
    text +=
      filters.producer_id.split("-")[1] +
      " " +
      filters.producer_id.split("-")[2] +
      " " +
      filters.producer_id.split("-")[3]
  }
  return text
}

//Función para checkear los estados de cada filtro
export const checkCurrentFilters = (filters, filter, value) => {
  if (filters?.operation !== "") {
    switch (filter) {
      case "location": {
        if (filters?.location) {
          if (
            filters.location.find(
              element =>
                element.name.toString().toLowerCase() ==
                value?.name?.toString().toLowerCase()
            )
          ) {
            return true
          }
        }
        return false
      }
      case "type": {
        if (
          filters?.type?.find(
            element =>
              element.name.toString().toLowerCase() ==
              value.toString().toLowerCase()
          )
        ) {
          return true
        }
        return false
      }
      case "operation": {
        if (filters.operation) {
          if (filters.operation.toLowerCase() === value.toLowerCase()) {
            return true
          }
        }
        return false
      }
      case "is_off": {
        if (filters?.price?.is_off === true) {
          return true
        }
        return false
      }
      case "price-type": {
        if (filters?.price?.type.toLowerCase().includes(value.toLowerCase())) {
          return true
        }
        return false
      }
      case "surface-type": {
        if (filters?.surface?.type.toLowerCase() === value.toLowerCase()) {
          return true
        }
        return false
      }
      case "environments": {
        return false
      }
      case "age": {
        if (filters.age !== "") {
          if (filters.age?.toLowerCase() === value?.toLowerCase()) {
            return true
          }
        }
        return false
      }
      case "environments_types": {
        if (
          filters?.environments_types?.find(
            element =>
              element.name.toString().toLowerCase() ===
              value.name.toString().toLowerCase()
          )
        ) {
          return true
        }
        return false
      }
      case "generals": {
        if (
          filters?.generals?.find(
            element =>
              element.name.toString().toLowerCase() ==
              value.name.toString().toLowerCase()
          )
        ) {
          return true
        }
        return false
      }
      case "services": {
        if (
          filters?.services?.find(
            element =>
              element.name.toString().toLowerCase() ==
              value.name.toString().toLowerCase()
          )
        ) {
          return true
        }
        return false
      }
      case "specials": {
        // if (filters.specials.find(element => element.name.toString().toLowerCase() == value.name.toString().toLowerCase())){
        if (
          filters.specials.find(
            element => element?.id.toString() === value?.id.toString()
          )
        ) {
          return true
        }
        return false
      }
    }
  }
  return false
}

//Función para actualizar el listado de filtros
export const updateFilters = (
  setFilters,
  filters,
  filter,
  value,
  params = {}
) => {
  // Params = mediciones
  const { arsMax, usdMax, totMax, cubMax } = params
  switch (filter) {
    //
    case "allLocations": {
      //Cambio todas juntas (normalmente actualizando barrios)
      setFilters(filters => ({
        ...filters,
        location: value,
      }))
      break
    }
    case "address": {
      setFilters(filters => ({
        ...filters,
        address: value,
      }))
      break
    }
    case "location": {
      if (filters.location.find(element => element.id == value.id)) {
        const newLocations = filters.location.filter(
          element => element.id !== value.id
        )
        setFilters(filters => ({
          ...filters,
          location: newLocations,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          location: [...filters.location, value],
        }))
        break
      }
    }
    case "type": {
      if (filters.type.find(element => element.id == value.id)) {
        const newTypes = filters.type.filter(element => element.id !== value.id)
        setFilters(filters => ({
          ...filters,
          type: newTypes,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          type: [...filters.type, value],
        }))
        break
      }
    }
    case "operation": {
      setFilters(filters => ({
        ...filters,
        operation: value,
      }))
      break
    }
    case "is_off": {
      setFilters(filters => ({
        ...filters,
        price: { ...filters.price, is_off: !filters.price.is_off },
      }))
      break
    }
    case "price-type": {
      if (
        value.toLowerCase() === "dolares" ||
        value.toLowerCase() === "pesos" ||
        value.toLowerCase() === "null"
      ) {
        if (value == "null") {
          setFilters(filters => ({
            ...filters,
            price: { ...filters.price, type: "" },
          }))
          break
        }
        setFilters(filters => ({
          ...filters,
          price: { ...filters.price, type: value },
        }))
        break
      }
    }
    case "price-min": {
      setFilters(filters => ({
        ...filters,
        price: { ...filters.price, priceMin: value },
      }))
      break
    }
    case "price-max": {
      if (value == 200000 && filters.price.priceMax == value) {
        setFilters(filters => ({
          ...filters,
          price: { ...filters.price, priceMax: 5000000 },
        }))
        break
      }
      setFilters(filters => ({
        ...filters,
        price: { ...filters.price, priceMax: value },
      }))
      break
    }
    case "price": {
      var priceDefaultMax = 0
      if (filters.price.type.toLowerCase() == "dolares") {
        priceDefaultMax = usdMax
      } else {
        priceDefaultMax = arsMax
      }
      setFilters(filters => ({
        ...filters,
        price: { ...filters.price, priceMax: priceDefaultMax, priceMin: 0 },
      }))
      break
    }
    case "surface-type": {
      if (value.toLowerCase() == "total" || value.toLowerCase() == "cubierta") {
        // if(value.toLowerCase() == filters.surface.type.toLowerCase()){
        //     setFilters((filters) => ({
        //         ...filters,
        //         surface:{...filters.surface,
        //             type:''
        //         }
        //     }))
        //     break;
        // }
        setFilters(filters => ({
          ...filters,
          surface: { ...filters.surface, type: value },
        }))
        break
      }
      setFilters(filters => ({
        ...filters,
        surface: { ...filters.surface, type: value },
      }))
      break
    }
    case "surface-min": {
      setFilters(filters => ({
        ...filters,
        surface: { ...filters.surface, surfaceMin: value },
      }))
      break
    }
    case "surface-max": {
      setFilters(filters => ({
        ...filters,
        surface: { ...filters.surface, surfaceMax: value },
      }))
      break
    }
    case "surface": {
      var surfaceDefaultMax = 0
      if (filters.surface.type.toLowerCase() == "total") {
        surfaceDefaultMax = totMax
      } else {
        surfaceDefaultMax = cubMax
      }
      setFilters(filters => ({
        ...filters,
        surface: { ...filters.surface, surfaceMax: "", surfaceMin: "" },
      }))
      break
    }
    case "environments": {
      setFilters(filters => ({
        ...filters,
        environments: value,
      }))
      break
    }
    case "bedrooms": {
      setFilters(filters => ({
        ...filters,
        bedrooms: value,
      }))
      break
    }
    case "age": {
      setFilters(filters => ({
        ...filters,
        age: value,
      }))
      break
    }
    case "environments_types": {
      if (filters.environments_types.find(element => element.id == value.id)) {
        const newEnvironments = filters.environments_types.filter(
          element => element.id !== value.id
        )
        setFilters(filters => ({
          ...filters,
          environments_types: newEnvironments,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          environments_types: [...filters.environments_types, value],
        }))
        break
      }
    }
    case "generals": {
      if (filters.generals.find(element => element.id == value.id)) {
        const newGenerals = filters.generals.filter(
          element => element.id !== value.id
        )
        setFilters(filters => ({
          ...filters,
          generals: newGenerals,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          generals: [...filters.generals, value],
        }))
        break
      }
    }
    case "services": {
      if (filters.services.find(element => element.id == value.id)) {
        const newServices = filters.services.filter(
          element => element.id !== value.id
        )
        setFilters(filters => ({
          ...filters,
          services: newServices,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          services: [...filters.services, value],
        }))
        break
      }
    }
    case "specials": {
      if (
        filters.specials.find(
          element => element.id.toString() == value.id.toString()
        )
      ) {
        const newSpecials = filters.specials.filter(
          element => element.id.toString() !== value.id.toString()
        )
        setFilters(filters => ({
          ...filters,
          specials: newSpecials,
        }))
        break
      } else {
        setFilters(filters => ({
          ...filters,
          specials: [...filters.specials, value],
        }))
        break
      }
    }
  }
}

//Función para actualizar la ruta según los filtros
export const updateRouter = filters => {
  if (filters.operation) {
    // los guiones son los espacios en las palabras, cada variables se separa con una coma ","
    var router = "/" + filters.operation.toLowerCase().replace(/ /g, "-")
    if (filters.type != "") {
      router += "/"
      for (let index = 0; index < filters?.type?.length; index++) {
        if (
          !router.includes(
            filters.type[index].name.toLowerCase().replace(/ /g, "-")
          )
        ) {
          router += filters.type[index].name
            .toString()
            .toLowerCase()
            .replace(" ", "-")
          if (index < filters?.type?.length - 1) {
            router += ","
          }
        }
      }
      if (router[router.length - 1] == ",") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (filters.location != "") {
      router += "/"
      console.log(filters.location)
      for (let index = 0; index < filters?.location?.length; index++) {
        if (
          !router.includes(
            filters.location[index].name.toLowerCase().replace(/ /g, "-")
          )
        ) {
          router += filters.location[index].name
            .toLowerCase()
            .replace("/", "")
            .replace(/ /g, "-")
          if (index < filters.location.length - 1) {
            router += ","
          }
        }
      }
    }
    if (filters.price.is_off) {
      router += "/propiedades-retasadas"
    }
    if (filters.address) {
      router += "/en-calle-" + filters.address.replaceAll(" ", "-")
    }
    if (
      filters?.price?.type ||
      filters?.price?.minPrice ||
      filters?.price?.maxPrice
    ) {
      router += "/"
      if (filters.price.type) {
        router += "" + filters.price.type.toLowerCase() + "-"
      }
      if (filters.price.type) {
        router += "desde-" + filters.price.priceMin + "-"
      }
      if (filters.price.type) {
        router += "hasta-" + filters.price.priceMax + "-"
      }
      // Borro si hay algún guón de más
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (
      filters?.surface?.type ||
      filters?.surface?.surfaceMin ||
      filters?.surface?.surfaceMax
    ) {
      router += "/"
      if (filters.surface.type) {
        router += "" + filters.surface.type.toLowerCase() + "-"
      }
      if (filters.surface.type) {
        router += "desde-" + filters.surface.surfaceMin + "-"
      }
      if (filters.surface.type) {
        router += "hasta-" + filters.surface.surfaceMax + "-"
      }
      // Borro si hay algún guón de más
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (filters.age != "" && filters.age !== undefined) {
      router += "/"
      router +=
        filters?.age
          ?.toLowerCase()
          .replace(/ /g, "-")
          .replace("años", "")
          .replace("+", "mas") +
        (filters?.age?.includes("años") ? "de-antiguedad" : "")
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (filters.environments !== "") {
      router += "/"
      router +=
        "desde-" +
        filters?.environments
          ?.toLowerCase()
          .replace(/ /g, "")
          .replace("-", "-hasta-") +
        "-ambientes"
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (filters.bedrooms !== "") {
      router += "/"
      router +=
        "desde-" +
        filters?.bedrooms
          ?.toLowerCase()
          .replace(/ /g, "")
          .replace("-", "-hasta-") +
        "-dormitorios"
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (filters.environments_types != "") {
      router += "/"
      for (
        let index = 0;
        index < filters?.environments_types?.length;
        index++
      ) {
        if (
          !router.includes(
            filters.environments_types[index].name
              .toLowerCase()
              .replace(/ /g, "-")
          )
        ) {
          router += filters.environments_types[index].name
            .toLowerCase()
            .replace(/ /g, "-")
          if (index < filters.environments_types.length - 1) {
            router += ","
          }
        }
      }
      if (router[router.length - 1] == "-") {
        router = router.substring(0, router.length - 1)
      }
    }
    if (
      filters?.generals != "" ||
      filters?.services != "" ||
      filters?.specials != ""
    ) {
      router += "/"
      //Agrego generales,services,specials separados por comas
      for (let index = 0; index < filters?.generals?.length; index++) {
        if (
          !router.includes(
            filters.generals[index].name.toLowerCase().replace(/ /g, "-")
          )
        ) {
          router += filters.generals[index].name
            .toLowerCase()
            .replace(/ /g, "-")
          router += ","
        }
      }
      for (let index = 0; index < filters?.services?.length; index++) {
        if (
          !router.includes(
            filters.services[index].name.toLowerCase().replace(/ /g, "-")
          )
        ) {
          router += filters.services[index].name
            .toLowerCase()
            .replace(/ /g, "-")
          router += ","
        }
      }
      for (let index = 0; index < filters?.specials?.length; index++) {
        if (
          !router.includes(
            filters.specials[index].name.toLowerCase().replace(/ /g, "-")
          )
        ) {
          router += filters.specials[index].name
            .toLowerCase()
            .replace(/ /g, "-")
          router += ","
        }
      }
      if (
        router[router.length - 1] == "-" ||
        router[router.length - 1] == ","
      ) {
        router = router.substring(0, router.length - 1)
      }
    }
    // Borro si hay alguna coma de más
    if (router[router.length - 1] == "," || router[router.length - 1] == "/") {
      router = router.substring(0, router.length - 1)
    }

    router = router.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    console.log(router)
    history.pushState(null, "", router)
  }
}

//Funcion para leer la ruta y cargar los filtros de la URL
export const loadFiltersRoute = (
  location,
  setFilters,
  filters,
  data,
  specials,
  setFiltersLoading,
  allLocations = [],
  listLocationLite = []
) => {
  for (const values of location) {
    // Obtengo las rutas separadas por " / "
    if (values != "" && data) {
      var iteratorGroup = values.split(",") // Separo esos valores en " , "
      for (const iteratorSingle of iteratorGroup) {
        var iteratorValue = iteratorSingle.replace(/-/g, " ") // Parcheo los espacios que le puse para pasarlos por url
        if (
          iteratorValue.toLowerCase() === "venta" ||
          iteratorValue.toLowerCase() === "alquiler" ||
          iteratorValue.toLowerCase() === "alquiler-temporario"
        ) {
          // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
          setFilters({
            ...filters,
            operation: iteratorValue,
          })
        }
        if (
          iteratorValue.toLowerCase().includes("pesos") ||
          iteratorValue.toLowerCase().includes("dolares")
        ) {
          // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
          const splitIterator = iteratorValue.split(" ")
          setFilters(filters => ({
            ...filters,
            price: { ...filters.price, type: splitIterator[0] },
          }))
          if (splitIterator[2] > 0) {
            setFilters(filters => ({
              ...filters,
              price: { ...filters.price, priceMin: splitIterator[2] },
            }))
          }
          if (splitIterator[4] > 0) {
            setFilters(filters => ({
              ...filters,
              price: { ...filters.price, priceMax: splitIterator[4] },
            }))
          }
        }
        if (
          iteratorValue.toLowerCase().includes("total") ||
          iteratorValue.toLowerCase().includes("cubierta")
        ) {
          // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
          const splitIterator = iteratorValue.split(" ")
          setFilters(filters => ({
            ...filters,
            surface: { ...filters.surface, type: splitIterator[0] },
          }))
          if (splitIterator[2] > 0) {
            setFilters(filters => ({
              ...filters,
              surface: { ...filters.surface, surfaceMin: splitIterator[2] },
            }))
          }
          if (splitIterator[4] > 0) {
            setFilters(filters => ({
              ...filters,
              surface: { ...filters.surface, surfaceMax: splitIterator[4] },
            }))
          }
        }
        if (
          iteratorValue.toLowerCase().includes("estrenar") ||
          iteratorValue.toLowerCase().includes("construccion")
        ) {
          setFilters(filters => ({
            ...filters,
            age: iteratorValue.toLowerCase().replace(/-/g, " "),
          }))
        }
        if (iteratorValue.toLowerCase().includes("retasadas")) {
          setFilters(filters => ({
            ...filters,
            price: { ...filters.price, is_off: true },
          }))
        }
        if (iteratorValue.toLowerCase().includes("antiguedad")) {
          setFilters(filters => ({
            ...filters,
            age: iteratorValue
              .toLowerCase()
              .replace(/-/g, " ")
              .replace("de antiguedad", "años")
              .replace("mas", "+"),
          }))
        }
        if (iteratorValue.toLowerCase().includes("ambientes")) {
          setFilters(filters => ({
            ...filters,
            environments:
              iteratorValue.toLowerCase().split(" ")[1] +
              "-" +
              iteratorValue.toLowerCase().split(" ")[3],
          }))
        }
        if (iteratorValue.toLowerCase().includes("dormitorios")) {
          setFilters(filters => ({
            ...filters,
            bedrooms:
              iteratorValue.toLowerCase().split(" ")[1] +
              "-" +
              iteratorValue.toLowerCase().split(" ")[3],
          }))
        }
        if (iteratorValue.toLowerCase().includes("en calle")) {
          setFilters(filters => ({
            ...filters,
            address: iteratorValue.toLowerCase().replaceAll("en calle ", ""),
          }))
        }
        for (const types of data?.objects?.property_types) {
          // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (types)
          if (
            iteratorValue.toLowerCase() ===
              types.type
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") &&
            !filters.type.find(element => element.id === types.id)
          ) {
            // Los en minusculas que coinciden los agrego
            setFilters(filters => ({
              ...filters,
              type: [...filters.type, { id: types.id, name: types.type }],
            }))
          }
        }
        // for (const locations of data.objects.locations) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
        for (const locations of getAllLocations(
          allLocations,
          data,
          data.objects.locations_tree
        )) {
          console.log(locations)
          // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
          if (
            iteratorValue
              ?.toLowerCase()
              ?.replace(/ /g, "-")
              ?.normalize("NFD")
              ?.replace(/[\u0300-\u036f]/g, "") ===
            locations?.location_name
              ?.toLowerCase()
              ?.replace(/ /g, "-")
              ?.normalize("NFD")
              ?.replace(/[\u0300-\u036f]/g, "")
          ) {
            // Los que en minusculas que coinciden los agrego
            if (
              filters.location.find(
                element =>
                  element.name
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "") ===
                  iteratorValue
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
              )
            ) {
            } else {
              if (
                !filters.location.find(
                  element => element.id === locations.location_id
                )
              ) {
                setFilters(filters => ({
                  ...filters,
                  location: [
                    ...filters.location.filter(
                      element => element.id !== locations.location_id
                    ),
                    {
                      id: locations.location_id,
                      name: locations.location_name,
                      type: locations?.type,
                    },
                  ],
                }))
              }
            }
          }
        }
        for (const tags of data.objects.tags) {
          // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
          if (iteratorValue.toLowerCase() !== "oficina") {
            if (iteratorValue.toLowerCase() === tags.tag_name.toLowerCase()) {
              // Los que en minusculas que coinciden los agrego
              switch (tags.tag_type) {
                case 1: {
                  setFilters(filters => ({
                    ...filters,
                    services: [
                      ...filters.services,
                      { id: tags.tag_id, name: tags.tag_name },
                    ],
                  }))
                  break
                }
                case 2: {
                  setFilters(filters => ({
                    ...filters,
                    environments_types: [
                      ...filters.environments_types,
                      { id: tags.tag_id, name: tags.tag_name },
                    ],
                  }))
                  break
                }
                case 3: {
                  setFilters(filters => ({
                    ...filters,
                    generals: [
                      ...filters.generals,
                      { id: tags.tag_id, name: tags.tag_name },
                    ],
                  }))
                  break
                }
              }
            }
          }
        }
        // for (const special of specials) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (specials)
        //     if(iteratorValue.toLowerCase() === special.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && !filters.specials.find(element => element.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === special.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){ // Los que en minusculas que coinciden los agrego
        //         setFilters((filters) => ({
        //                 ...filters,
        //                 specials:[...filters.specials,{id:special.id,name:special.name}]
        //         }))
        //     }
        // }
        if (
          iteratorValue.toString().toLowerCase() === "1 ambiente" ||
          iteratorValue.toString().toLowerCase() === "2 ambientes" ||
          iteratorValue.toString().toLowerCase() === "3 ambientes" ||
          iteratorValue.toString().toLowerCase() === "4 ambientes" ||
          iteratorValue.toString().toLowerCase() === "5 ambientes" ||
          iteratorValue.toString().toLowerCase() === "6 ambientes" ||
          iteratorValue.toString().toLowerCase() === "7 ambientes" ||
          iteratorValue.toString().toLowerCase() === "8 ambientes" ||
          iteratorValue.toString().toLowerCase() === "mas de 9 ambientes"
        ) {
          // Los que en minusculas que coinciden los agrego
          setFilters(filters => ({
            ...filters,
            environments: [iteratorValue],
          }))
          break
        }
      }
    } else {
    }
  }
  console.log(location)
  setFiltersLoading(false)
}

//Función para localizar y ver cantidad de localidades segun filtros activos
export const getLocationCount = (filters, location) => {
  return filters.locations.find(
    element => element.location_id === location.location_id
  )
    ? filters.locations.find(
        element => element.location_id === location.location_id
      ).count
    : 0
}

//Función para localizar y ver cantidad de tipos de propiedades segun filtros activos
export const getTypeCount = (filters, type) => {
  return filters.property_types.find(element => element.id === type.id)
    ? filters.property_types.find(element => element.id === type.id).count
    : 0
}

//Función para localizar y ver cantidad de tags segun filtros activos
export const getTagCount = (filters, tag) => {
  return filters.tags.find(element => element.tag_id === tag.tag_id)
    ? filters.tags.find(element => element.tag_id === tag.tag_id).count
    : 0
}

//Función para localizar y ver cantidad de custom-tags segun filtros activos
export const getCustomTagCount = (filters, tag) => {
  if (filters) {
    return filters.find(element => element.tag_id === tag.tag_id)
      ? filters.tags.find(element => element.tag_id === tag.tag_id).count
      : 0
  }
}

export const getGrandfathers = allLocation => {
  if (allLocation?.locations) {
    if (allLocation?.locations?.Argentina) {
      return Object?.entries(allLocation?.locations?.Argentina)
        .filter(
          element =>
            element[0].toLowerCase() !== "count" &&
            element[0].toLowerCase() !== "id"
        )
        .sort(function (a, b) {
          return a[0].toString().localeCompare(b[0].toString())
        })
    }
  }
  return []
}

export const getFathers = grandFather => {
  const names = Object?.entries(grandFather[1]).filter(
    element =>
      element[0].toLowerCase() !== "count" && element[0].toLowerCase() !== "id"
  )
  return names?.sort(function (a, b) {
    return a[0].toString().localeCompare(b[0].toString())
  })
}

export const getChildrens = father => {
  return Object?.entries(father[1])
    .filter(
      element =>
        element[0].toLowerCase() !== "count" &&
        element[0].toLowerCase() !== "id"
    )
    .sort(function (a, b) {
      return a[0].toString().localeCompare(b[0].toString())
    })
}

export const normalizeName = location => {
  return location
    .replaceAll(" ", "-")
    .replaceAll(".", "")
    .replaceAll(".", "")
    .replaceAll(")", "")
    .replaceAll("(", "")
    .replaceAll("/", "-")
}

export const group_three_mediacore = locations => {
  let response = []
  let counter = 0
  for (const country of locations) {
    response.push({
      location_name: country.name,
      location_id: counter,
      type: "country",
    })
    counter++
    for (const grandfather of country.data) {
      response.push({
        location_name: grandfather.name,
        location_id: counter,
        type: "grandfather",
      })
      counter++
      for (const father of grandfather.data) {
        response.push({
          location_name: father.name,
          location_id: counter,
          type: "father",
        })
        counter++
        for (const children of father.data) {
          response.push({
            location_name: children.name,
            location_id: counter,
            type: "neighborhood",
          })
          counter++
        }
      }
    }
  }
  return response
}

export const getAllLocations = (
  allLocations,
  listLocationLite = {},
  liteLocationsMediacore = {}
) => {
  const locations = []
  const liteLocations = listLocationLite?.objects?.locations || []
  if (liteLocationsMediacore.length > 0) {
    for (let mediacoreLocation of group_three_mediacore(
      liteLocationsMediacore
    )) {
      if (
        !locations.find(
          element => element.location_id === mediacoreLocation.location_id
        )
      ) {
        locations.push({
          location_name: mediacoreLocation.location_name.replaceAll("/", ""),
          location_id: mediacoreLocation.location_id,
          type: mediacoreLocation.type,
        })
      }
    }
  } else {
    for (let grandfather of getGrandfathers(allLocations)) {
      locations.push({
        location_name: grandfather[0],
        location_id: grandfather[1].location_id,
      })
      for (let father of getFathers(grandfather)) {
        locations.push({ location_name: father[0], location_id: father[1].id })
        for (let children of getChildrens(father)) {
          locations.push({
            location_name: children[0],
            location_id: children[1].id,
          })
        }
      }
    }
    for (let liteLocation of liteLocations) {
      if (
        !locations.find(
          element => element.location_id === liteLocation.location_id
        )
      ) {
        locations.push({
          location_name: liteLocation.location_name,
          location_id: liteLocation.location_id,
        })
      }
    }
  }
  console.log(locations)
  return locations
}
